body {
	background:#000
}
svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	max-width: 50vh;
	max-height: 50vw;
}